import React from 'react'
import { Link } from 'gatsby'

const Footer: React.FC = () => {
    return (
        <footer>
            <p>© 2022 Munich</p>
            <Link to="/impressum">Impressum</Link>
            <a href="mailto:information@waswahr.de">information@waswahr.de</a>
        </footer>
    )
}

export default Footer
