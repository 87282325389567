import React from 'react'
import { Link } from 'gatsby'
import { Interview } from '../util/types'

interface Props {
    title: string
    items: Interview[]
    noBorder?: boolean
}

const Grid: React.FC<Props> = ({ title, items, noBorder }) => {
    return (
        <>
            <h2 className="grid-title">{title}</h2>
            <div className="grid">
                {items.map((item, index) => (
                    <Link
                        to={`/interview/${item.url}`}
                        className={`grid-item ${noBorder ? 'grid-item-noborder' : ''}`}
                        key={index}
                    >
                        <div
                            className="grid-item-cover"
                            style={{ backgroundImage: `url(${item.cover.asset.url})` }}
                        >
                            <span>{item.video_duration}</span>
                        </div>
                        <div className="grid-item-content">
                            <p className="grid-item-title">
                                {item.title}
                                <br />
                                {item.subtitle}
                            </p>
                            <p className="grid-item-author">{item.interviewee}</p>
                            <p className="grid-item-date">
                                {new Date(item.date).toLocaleDateString('de-DE', {
                                    day: '2-digit',
                                    month: 'short',
                                    year: 'numeric'
                                })}
                            </p>
                        </div>
                    </Link>
                ))}
            </div>
        </>
    )
}

export default Grid
