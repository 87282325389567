import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Grid from '../components/Grid'
import Footer from '../components/Footer'
import Layout from '../Layout'
import SEO from '../SEO'

import { Interview as InterviewType } from '../util/types'

interface Props extends PageProps {
    data: { allSanityInterview: { nodes: InterviewType[] } }
}

const Interviews: React.FC<Props> = ({ data }) => {
    const interviews = data.allSanityInterview.nodes

    const lastInterview = interviews.pop()
    if (lastInterview) interviews.unshift(lastInterview)

    return (
        <div>
            <Layout currentPage="interviews">
                <SEO title="Was Wa(h)r - Interviews" />
            </Layout>

            <Grid title="((Alle)) Interviews" items={interviews} />

            <Footer />
        </div>
    )
}

export const query = graphql`
    query {
        allSanityInterview(sort: { order: ASC, fields: title }) {
            nodes {
                url
                title
                subtitle
                interviewee
                date
                cover {
                    asset {
                        url
                    }
                }
                video_duration
            }
        }
    }
`

export default Interviews
